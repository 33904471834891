import {DOUBLE_ROUNDS_KEY} from "data/constants";
import {Bindings} from "data/constants/bindings";
import type {ISponsorProvider} from "data/providers/api/sponsor.provider";
import type {IJSONProvider} from "data/providers/json/json.provider";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";

export interface ISettings {
	sponsorImage: string;
	sponsorLink: string;
	offSeason: boolean;
	doublePointsRoundIds: number[];
	isSecondPhaseEnabled: boolean;
}

export interface ISponsorStore {
	fetchSettings: () => void;
	sendSponsorFlag: () => void;

	get settings(): ISettings | undefined;

	get sharedDoubleRounds(): number[];
}

@injectable()
export class SponsorStore implements ISponsorStore {
	@observable private _settings: ISettings | undefined;

	constructor(
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider,
		@inject(Bindings.SponsorProvider) private _sponsorProvider: ISponsorProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	public get settings(): ISettings | undefined {
		return this._settings;
	}

	public get sharedDoubleRounds(): number[] {
		try {
			const rounds: number[] = this.settings?.doublePointsRoundIds || [];
			const storageRounds = JSON.parse(
				localStorage.getItem(DOUBLE_ROUNDS_KEY) || "[]"
			) as number[];
			return [...rounds, ...storageRounds];
		} catch (e) {
			return this.settings?.doublePointsRoundIds || [];
		}
	}

	@action
	async fetchSettings() {
		try {
			const {data} = await this._jsonProvider.settings();

			runInAction(() => {
				this._settings = data;
			});
		} catch (e) {
			this._modalsStore.showError({
				title: "modal.error.title",
				message: "common.error.sponsor",
				translate: true,
			});
		}
	}

	@action
	async sendSponsorFlag() {
		try {
			await this._sponsorProvider.sendSponsorFlag();
		} catch (e) {
			this._modalsStore.showError({
				title: "modal.error.title",
				message: "common.error.sponsor",
				translate: true,
			});
		}
	}
}
