import {inject, injectable} from "inversify";
import type {IUser} from "data/stores/user/user.store";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {IRegisterPayload} from "data/providers/api/auth.api.provider";

export interface IRegistrationPayload {
	displayName: string;
	email: string;
	password: string;
}

export interface IUpdateUserPayload {
	displayName?: string;
	isReturningModalViewed?: boolean;
	isRankingModalViewed?: boolean;
	isTutorialViewed?: boolean;
}

export interface IUsername {
	username: string;
}

export type TUserResponse = IApiResponse<{user: IUser}>;

export interface IUserApiProvider {
	register: (params: IRegisterPayload) => Promise<AxiosResponse<TUserResponse>>;
	update: (params: IUpdateUserPayload) => Promise<AxiosResponse<TUserResponse>>;
	user: () => Promise<AxiosResponse<TUserResponse>>;
	checkUsername: (params: IUsername) => Promise<AxiosResponse>;
	deactivateAccount: () => Promise<AxiosResponse>;
}

@injectable()
export class UserApiProvider implements IUserApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	checkUsername = (params: IUsername) =>
		this._http.post<void>("user/check-display-name", {displayName: params.username});

	deactivateAccount = () => this._http.post<void>("user/deactivate_account");

	register = (params: IRegisterPayload) =>
		this._http.post<TUserResponse>("user/register", params);

	update = (params: IUpdateUserPayload) => this._http.post<TUserResponse>("user/update", params);

	user = () => this._http.get<TUserResponse>("user");
}
