import {
	action,
	IReactionDisposer,
	makeAutoObservable,
	observable,
	reaction,
	runInAction,
} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILeaderboardStore} from "data/stores/leaderboard/leaderboard.store";
import {SortOrder} from "data/enums";
import type {ILeaderboardRank} from "data/types/api";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUserStore} from "data/stores/user/user.store";

export interface ILeaderboardController extends ViewController {
	getIsSortActiveClass: (orderBy: SortOrder) => string;
	sortByPoints: () => void;
	getUserClass: (userId: number | null) => string;

	get i18n(): ILocalizationStore;

	get isLoading(): boolean;

	get isRequested(): boolean;

	get isUserInList(): boolean;

	get rankings(): ILeaderboardRank[];

	get userRanking(): ILeaderboardRank | null;

	get hasNextPage(): boolean;

	loadMore(): void;
}

@injectable()
export class LeaderboardController implements ILeaderboardController {
	@observable private _isRequested: boolean = false;
	@observable private _subscriptions$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.LeaderboardStore) private _leaderboardStore: ILeaderboardStore
	) {
		makeAutoObservable(this);
	}

	public get isRequested(): boolean {
		return this._isRequested;
	}

	public get isLoading(): boolean {
		return this._leaderboardStore.isLoading;
	}

	public get hasNextPage(): boolean {
		return this._leaderboardStore.hasNextPage;
	}

	public get isUserInList(): boolean {
		return this.rankings.some((e) => e.userId === this.userRanking?.userId);
	}

	public get rankings(): ILeaderboardRank[] {
		return this._leaderboardStore.rankings;
	}

	public get userRanking() {
		return this._leaderboardStore.leaderboardUser;
	}

	public loadMore = () => {
		void this._leaderboardStore.loadMoreLeaderboard();
	};

	dispose(): void {
		this._subscriptions$.forEach((dispose) => dispose());
	}

	init(param: void): void {
		const subscription = reaction(
			() => [this._leaderboardStore.orderBy, this._leaderboardStore.filters.type],
			() => this.fetchLeaderboards(),
			{fireImmediately: true}
		);

		this._subscriptions$.push(subscription);
	}

	onChange(param: void): void {
		return;
	}

	public getIsSortActiveClass = (orderByValue: SortOrder): string => {
		return orderByValue === this._leaderboardStore.orderBy ? "active" : "";
	};

	public sortByPoints = (): void => {
		this.reverseOrder();
	};

	public getUserClass = (userId: number | null): string => {
		return userId === this._userStore.user?.id ? "user" : "";
	};

	@action
	private fetchLeaderboards(): void {
		this._isRequested = false;
		this._leaderboardStore.fetchLeaderboards().finally(() => {
			runInAction(() => {
				this._isRequested = true;
			});
		});
	}

	private reverseOrder() {
		const {orderBy} = this._leaderboardStore;
		this._leaderboardStore.orderBy =
			orderBy === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
	}
}
