export const createFanHubEvent = (evtguid: string) => {
	try {
		const trkGuid = process.env.REACT_APP_FANHUB_TAGGING || "";
		window.FanHub?.createEvent(evtguid, trkGuid, window.location.href);
	} catch (e) {
		console.error(e);
	}
};

export const createFanHubEventSingleTime = (evtguid: string) => {
	const isViewed = localStorage.getItem(evtguid) === "true";

	if (!isViewed) {
		createFanHubEvent(evtguid);
		localStorage.setItem(evtguid, "true");
	}
};
