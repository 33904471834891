import type {IUserStore} from "data/stores/user/user.store";
import {createFanHubEvent} from "data/utils/FanHubTagging";
import {
	action,
	IReactionDisposer,
	makeAutoObservable,
	observable,
	reaction,
	runInAction,
} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ITutorialStep, ITutorialStore} from "data/stores/tutorial/tutorial.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ISecretGateController} from "views/controllers/secrete_gate/secret_gate.controller";

export interface IModalTutorialController extends ViewController {
	onClose: () => void;
	closeConfirm: () => void;
	goToFirstStep: () => void;
	goToStep: (currentSlide: number, nextSlide: number) => void;
	goToStepFromSteps: (currentSlide: number, nextSlide: number) => void;

	get i18n(): ILocalizationStore;

	get steps(): ITutorialStep[];

	get currentStep(): number;

	get isOpen(): boolean;
}

@injectable()
export class ModalTutorialController implements IModalTutorialController {
	@observable private _subscriptions$: IReactionDisposer[] = [];
	@observable private _currentStep: number = 0;

	constructor(
		@inject(Bindings.SecretGateController) public _secretGateController: ISecretGateController,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.TutorialStore) private _tutorialStore: ITutorialStore
	) {
		makeAutoObservable(this);
	}

	public get isOpen(): boolean {
		return this._tutorialStore.isTutorialOpen && this._secretGateController.isSecretPassed;
	}

	public get currentStep(): number {
		return this._currentStep;
	}

	public get steps(): ITutorialStep[] {
		return this._tutorialStore.steps;
	}

	dispose(): void {
		this._subscriptions$.forEach((dispose) => dispose());
	}

	init(param: void): void {
		const subscription = reaction(
			() => this.user,
			() => this.checkModal(),
			{fireImmediately: true}
		);

		this._subscriptions$.push(subscription);
	}

	onChange(param: void): void {
		return;
	}

	public closeConfirm = () => {
		createFanHubEvent("c5975cd8-a97f-45a7-9779-880f9c4cf0ae");
		this.onClose();
	};

	public onClose = (): void => {
		void this._userStore.update({
			isTutorialViewed: true,
		});
		this._tutorialStore.closeTutorialModal();
		this._currentStep = 0;
	};

	@action
	public goToStep = (currentSlide: number, nextSlide: number): void => {
		runInAction(() => {
			this._currentStep = nextSlide;
		});
	};

	@action
	public goToStepFromSteps = (currentSlide: number, nextSlide: number): void => {
		this._currentStep = nextSlide;
	};

	private get user() {
		return this._userStore.user;
	}

	@action
	public goToFirstStep = (): void => {
		this._currentStep = 1;
		createFanHubEvent("1c2029bc-a8c5-4fba-ad33-2ec3fcf6f56b");
	};

	private checkModal(): void {
		if (!this.user) {
			return;
		}

		if (!this.user?.isTutorialViewed) {
			this._tutorialStore.openTutorialModal();
		}
	}
}
