import {makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";
import stepTwoImage from "assets/img/tutorial/step-2.png";
import stepThreeImage from "assets/img/tutorial/step-3.png";
import stepFourImage from "assets/img/tutorial/step-4.png";
import stepFiveImage from "assets/img/tutorial/step-5.svg";
import PrizeText from "assets/img/modals/prize_title.svg";

export interface ITutorialStep {
	title_loco: string;
	title_alt: string;
	text_loco: string;
	text_alt: string;
	image?: string;
	hasButton?: boolean;
}

export interface ITutorialStore {
	get steps(): ITutorialStep[];

	get isTutorialOpen(): boolean;

	openTutorialModal(): void;

	closeTutorialModal(): void;
}

@injectable()
export class TutorialStore implements ITutorialStore {
	@observable private _isTutorialOpen: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	public get isTutorialOpen(): boolean {
		return this._isTutorialOpen;
	}

	public get steps(): ITutorialStep[] {
		return [
			{
				title_loco: "tutorial.step_1.title",
				title_alt: "WELCOME TO JERSEY MIKE'S HOCKEY PREDICTOR",
				text_loco: "tutorial.step_1.text",
				text_alt:
					"Think you know the NHL? <br /> Pick daily winners and earn Shore Points!<br/>Tap <span>“Next”</span> to learn how to play.",
				hasButton: true,
			},
			{
				title_loco: "tutorial.step_2.title",
				title_alt: "pick up to four winners each day",
				text_loco: "tutorial.step_2.text",
				image: stepTwoImage,
				text_alt:
					"Throughout the NHL Regular Season you can pick up to <span>four winners</span> from each day’s slate of games.",
			},
			{
				title_loco: "tutorial.step_3.title",
				title_alt: "build your streak",
				text_loco: "tutorial.step_3.text",
				image: stepThreeImage,
				text_alt:
					"If all your picks in the day are correct, you will build a streak: <span>One point</span> for each correct pick, but be careful..",
			},
			{
				title_loco: "tutorial.step_4.title",
				title_alt: "make a wrong pick <br /> and your streak ends",
				text_loco: "tutorial.step_4.text",
				text_alt:
					"That’s right: <br /> Pick your winners carefully because one wrong move, and you’re out!",
				image: stepFourImage,
			},
			{
				title_loco: "tutorial.step_5.title",
				title_alt: "DOUBLE POINTS TUESDAY",
				text_loco: "tutorial.step_5.text",
				image: PrizeText,
				text_alt:
					"Earn <span>Double</span> Shore and Leaderboard Points <br/> by making picks <span>every Tuesday</span>",
				hasButton: true,
			},
			{
				title_loco: "tutorial.step_6.title",
				title_alt: "earn shore points",
				text_loco: "tutorial.step_6.text",
				image: stepFiveImage,
				text_alt:
					"You will receive one <span>SHORE POINT</span> for every day you play the game, and bonus <span>SHORE POINT</span> for each successful pick!",
			},
			{
				title_loco: "tutorial.step_7.title",
				title_alt: "WIN TICKETS TO THE <br />ALL STAR GAME",
				text_loco: "tutorial.step_7.text",
				text_alt:
					"Build the longest streak before <span>December 31</span> and you'll <span>win tickets</span> to the <span>All Star Game</span> on <span>February 4, 2024.</span>",
				hasButton: true,
			},
		];
	}

	public closeTutorialModal(): void {
		this._isTutorialOpen = false;
	}

	public openTutorialModal(): void {
		this._isTutorialOpen = true;
	}
}
