import {Bindings} from "data/constants/bindings";
import {RoundStatus} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ISponsorStore} from "data/stores/sponsor/sponsor.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {first} from "lodash";
import {makeAutoObservable} from "mobx";

export interface IDoublePointsBannerController extends ViewController {
	get i18n(): ILocalizationStore;

	get isVisible(): boolean;
}

@injectable()
export class DoublePointsBannerController implements IDoublePointsBannerController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.SponsorStore) private _sponsorStore: ISponsorStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	private get sharedDoubleRounds() {
		return this._sponsorStore.sharedDoubleRounds;
	}

	private get nearestDoubleRound(): number | undefined {
		const doublePointsRounds = this.sharedDoubleRounds;
		const currentRound = this._roundsStore.currentRound;
		if (!doublePointsRounds || !currentRound) {
			return;
		}
		const futureRounds = doublePointsRounds.filter((roundId) => roundId >= currentRound.id);

		return first(futureRounds);
	}

	private get doublePointsRound() {
		if (!this.nearestDoubleRound) {
			return false;
		}
		return this._roundsStore.currentRound?.id === this.nearestDoubleRound;
	}

	get isVisible() {
		const isActive = this._roundsStore.currentRound?.status !== RoundStatus.Complete;

		return Boolean(this.doublePointsRound) && isActive;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onChange(param: void): void {
		return;
	}
}
