export const Bindings = {
	JSONProvider: Symbol("JSONProvider"),
	AuthApiProvider: Symbol("AuthApiProvider"),
	ApiHTTPClient: Symbol("ApiHTTPClient"),
	UserApiProvider: Symbol("UserApiProvider"),
	LeaguesApiProvider: Symbol("LeaguesApiProvider"),
	PredictionsApiProvider: Symbol("PredictionsApiProvider"),
	PasswordApiProvider: Symbol("PasswordApiProvider"),
	LeaderboardApiProvider: Symbol("LeaderboardApiProvider"),
	SponsorProvider: Symbol("SponsorProvider"),
	JsonHTTPClient: Symbol("JsonHTTPClient"),
	ContentJsonHTTPClient: Symbol("ContentJsonHTTPClient"),
	CountriesStore: Symbol("CountriesStore"),
	RoundsStore: Symbol("RoundsStore"),
	PlayersStore: Symbol("PlayersStore"),
	SquadsStore: Symbol("SquadsStore"),
	UserStore: Symbol("UserStore"),
	LocalizationStore: Symbol("LocalizationStore"),
	StaticContentStore: Symbol("StaticContentStore"),
	MenuStore: Symbol("MenuStore"),
	PredictionsStore: Symbol("PredictionsStore"),
	ChecksumStore: Symbol("ChecksumStore"),
	LeaguesStore: Symbol("LeaguesStore"),
	ModalsStore: Symbol("ModalsStore"),
	TutorialStore: Symbol("TutorialStore"),
	GameBarStore: Symbol("GameBarStore"),
	LeaderboardStore: Symbol("LeaderboardStore"),
	SponsorStore: Symbol("SponsorStore"),
	AuthController: Symbol("AuthController"),
	LoginController: Symbol("LoginController"),
	MyAccountController: Symbol("MyAccountController"),
	SecretGateController: Symbol("SecretGateController"),
	HelpController: Symbol("HelpController"),
	HelpListController: Symbol("HelpListController"),
	ContactUsController: Symbol("ContactUsController"),
	LiveScoreController: Symbol("LiveScoreController"),
	ForgotPasswordController: Symbol("ForgotPasswordController"),
	ResetPasswordController: Symbol("ResetPasswordController"),
	SessionController: Symbol("SessionController"),
	BootstrapController: Symbol("BootstrapController"),
	MyLeaguesController: Symbol("MyLeaguesController"),
	JoinLeaguesController: Symbol("JoinLeaguesController"),
	CreateLeagueController: Symbol("CreateLeagueController"),
	LeagueController: Symbol("LeagueController"),
	LeagueInviteController: Symbol("LeagueInviteController"),
	LeagueSettingsController: Symbol("LeagueSettingsController"),
	LeagueAboutController: Symbol("LeagueAboutController"),
	LeagueInviteFormController: Symbol("LeagueInviteFormController"),
	ModalErrorController: Symbol("ModalErrorController"),
	ModalConfirmController: Symbol("ModalConfirmController"),
	LandingController: Symbol("LandingController"),
	LocalizationController: Symbol("LocalizationController"),
	HeaderController: Symbol("HeaderController"),
	MenuController: Symbol("MenuController"),
	GameBarController: Symbol("GameBarController"),
	RoundSelectorController: Symbol("RoundSelectorController"),
	PicksController: Symbol("PicksController"),
	MatchCardController: Symbol("MatchCardController"),
	PickButtonController: Symbol("PickButtonController"),
	ActionBannerController: Symbol("ActionBannerController"),
	MatchStatusController: Symbol("MatchStatusController"),
	ModalLeaveController: Symbol("ModalLeaveController"),
	ModalLeaveNavigateController: Symbol("ModalLeaveNavigateController"),
	ModalTutorialController: Symbol("ModalTutorialController"),
	LeaderboardController: Symbol("LeaderboardController"),
	ModalPicksConfirmController: Symbol("ModalPicksConfirmController"),
	SharingController: Symbol("SharingController"),
	ScrollButtonController: Symbol("ScrollButtonController"),
	ModalLeaveAccountController: Symbol("ModalLeaveAccountController"),
	ModalGameLogicChangesController: Symbol("ModalGameLogicChangesController"),
	ModalReturnedUserController: Symbol("ModalReturnedUserController"),
	ModalRankingsInfoController: Symbol("ModalRankingsInfoController"),
	ModalPreDoublePointsController: Symbol("ModalPreDoublePointsController"),
	DoublePointsBannerController: Symbol("DoublePointsBannerController"),
	LeaderboardFiltersController: Symbol("LeaderboardFiltersController"),
};
