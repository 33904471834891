import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";

export interface IModalPreDoublePointsController extends ViewController {
	close: () => void;

	get i18n(): ILocalizationStore;

	get isOpen(): boolean;

	get isDoublePointsDay(): boolean;
}

@injectable()
export class ModalPreDoublePointsController implements IModalPreDoublePointsController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isDoublePointsDay(): boolean {
		return this._modalsStore.modal === ModalType.DOUBLE_POINTS;
	}

	get isOpen(): boolean {
		if (!this._modalsStore.modal) {
			return false;
		}
		return [ModalType.PRE_DOUBLE_POINTS, ModalType.DOUBLE_POINTS].includes(
			this._modalsStore.modal
		);
	}

	public close = () => {
		this._modalsStore.hideModal();
		this._modalsStore.showModal(ModalType.PICKS_CONFIRM);
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onChange(param: void): void {
		return;
	}
}
