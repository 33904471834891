import {createFanHubEvent} from "data/utils/FanHubTagging";
import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IRound, IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";

export interface IRoundSelectorController extends ViewController {
	navigatePrev: () => void;
	navigateNext: () => void;

	get roundName(): string | undefined;

	get isNextDisabled(): boolean;

	get isPrevDisabled(): boolean;
}

@injectable()
export class RoundSelectorController implements IRoundSelectorController {
	constructor(
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	public get isNextDisabled(): boolean {
		return this.roundIndex === this._roundsStore.availableToNavigateRounds.length - 1;
	}

	public get isPrevDisabled(): boolean {
		return this.roundIndex === 0;
	}

	public get roundName(): string | undefined {
		return this._roundsStore.currentRound?.name;
	}

	protected get roundIndex(): number {
		const currentRound = this._roundsStore.currentRound;
		const index = this._roundsStore.availableToNavigateRounds.findIndex(
			(e) => e.id === currentRound?.id
		);

		if (index === -1) {
			return 0;
		}

		return index === -1 ? 0 : index;
	}

	private get nextRound(): IRound | undefined {
		return this._roundsStore.availableToNavigateRounds[this.roundIndex + 1];
	}

	private get prevRound(): IRound | undefined {
		return this._roundsStore.availableToNavigateRounds[this.roundIndex - 1];
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onChange(param: void): void {
		return;
	}

	public navigatePrev = () => {
		if (!this.prevRound || this.isPrevDisabled) {
			return;
		}

		createFanHubEvent("e9ce4ff5-a50e-4d01-a6a9-7776328fab90");
		const roundId = this.prevRound.id;
		this.navigateToRound(roundId);
	};

	public navigateNext = () => {
		if (!this.nextRound || this.isNextDisabled) {
			return;
		}

		createFanHubEvent("2d828f63-687b-418a-bcd2-0de7d0a1e792");
		const roundId = this.nextRound.id;
		this.navigateToRound(roundId);
	};

	private navigateToRound(roundId: number): void {
		if (this._predictionsStore.localPredictions.length > 0) {
			this.openLeaveModal(roundId);
		} else {
			this._roundsStore.selectRoundById(roundId);
		}
	}

	private openLeaveModal(roundId: number): void {
		this._modalsStore.showModal(ModalType.ROUND_LEAVE, {data: roundId});
	}
}
