import {ViewController} from "data/types/structure";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {createFanHubEvent} from "data/utils/FanHubTagging";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import React from "react";
import {useNavigate} from "react-router-dom";
import {isEqual, sortBy, orderBy, toLower, deburr, lowerCase, get} from "lodash";
import type {
	IStaticContentStore,
	TArticle,
	ISections,
} from "data/stores/static_content/static_content.store";

interface IControllerProps {
	navigate: ReturnType<typeof useNavigate>;
	pathname: string;
}

export interface IHelpController extends ViewController<IControllerProps> {
	readonly i18n: ILocalizationStore;
	get tabs(): ISections["sections"];
	get contents(): TArticle[];
	get currentTab(): number | null;

	buildPathName: (pathname: string) => string;
	isActiveTab: (tabId: number) => boolean;
	isContactUs: (tabId: number) => boolean;
	trackEvent: (event: React.SyntheticEvent, value: unknown) => void;
	findSectionsById(sectionId: number): TArticle[];
}

@injectable()
export class HelpController implements IHelpController {
	private _currentTab: number | null = null;
	private _navigate!: IControllerProps["navigate"];

	private readonly trackMapper = {
		"to play": "8ad796fd-9da6-4fd6-8117-061627f33641",
		prize: "87ce9836-7427-4788-a9e4-7a60dd07fee1",
		faq: "fbf567df-efd2-49cc-ae08-ce623f362bb7",
		contact: "b9c370b7-78ac-4c9d-88d6-e02e5ba1fd8f",
		terms: "c835789b-eac8-447b-bf4d-952486ed09f7",
	};

	private static rootPath = "/help";

	get tabs() {
		return sortBy(this._staticContentStore.sections, "position");
	}

	get contents() {
		return this._staticContentStore.articles;
	}

	get currentTab() {
		return this._currentTab;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.StaticContentStore)
		private readonly _staticContentStore: IStaticContentStore
	) {
		makeAutoObservable(this);
	}

	public trackEvent = (event: React.SyntheticEvent, value: unknown) => {
		const name = this.tabs.find((tab) => tab.id === value)?.name;

		console.log(name);

		Object.keys(this.trackMapper).forEach((key) => {
			const value = get(this.trackMapper, key, undefined);
			if (name?.toLowerCase().includes(key) && value) {
				createFanHubEvent(value);
			}
		});
	};

	public findSectionsById(sectionId: number) {
		const sections = this.contents?.filter((article) => article.section_id === sectionId) ?? [];

		return orderBy(sections, ["position"], ["asc"]);
	}

	onChange(param: IControllerProps) {
		this.updatePathname(param.pathname);
	}

	private updatePathname = (pathname: string) => {
		const newTab = this.tabs.find((it) => pathname.includes(this.buildPathName(it.name)))?.id;

		if (newTab) {
			this.setCurrentTab(newTab);
		}
	};

	public setCurrentTab = (value: number) => {
		this._currentTab = value;
	};

	public isActiveTab = (tab: number) => {
		return isEqual(this._currentTab, tab);
	};

	public isContactUs = (tabId: number) => {
		const sections = this.findSectionsById(tabId);

		return Boolean(
			sections.find(
				({label_names, title}) =>
					label_names.includes("contact_us") || lowerCase(title).includes("contact us")
			)
		);
	};

	public buildPathName = (name: string) => {
		return toLower(deburr(name)).split(" ").join("-");
	};

	async init({navigate, pathname}: IControllerProps) {
		this._navigate = navigate;

		await this._staticContentStore.fetchStaticContent().catch((_err) => {
			// TODO Show error modal
		});

		pathname = pathname.endsWith("/") ? pathname.slice(0, -1) : pathname;

		if (isEqual(pathname, HelpController.rootPath)) {
			return navigate(this.buildPathName(this.tabs[0].name), {replace: true});
		}

		this.updatePathname(pathname);
	}
}
