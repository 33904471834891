import styled from "@emotion/styled";

export const ModalContent = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
	outline: none;
	max-width: 520px;
	width: 100%;
	background: #fff;
	padding: 20px;
	border-radius: 4px;
	text-align: center;

	@media screen and (max-width: 640px) {
		max-width: unset;
		left: 24px;
		right: 24px;
		width: auto;
		transform: translateY(-50%);
	}

	button {
		width: 100%;
	}
`;

export const ModalCloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	button {
		min-width: 24px;
		max-width: 24px;
		width: 24px;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: transparent;
		color: #000000;
		border: none;

		&:hover,
		&:active,
		&:focus {
			color: #000000;
			background: transparent;
		}
	}
`;

export const ModalIcon = styled.div`
	width: 56px;
	height: 56px;
	background: var(--correct-incorrect-incorrect-darker);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		width: 32px;
		height: auto;
		fill: var(--icons-white);
	}
`;

export const ModalTitle = styled.h2`
	color: var(--text-default);
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: bold;
	line-height: 110%;
	text-transform: uppercase;
`;

export const ModalText = styled.p`
	color: var(--text-default);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;

	b {
		font-weight: bold;
	}
`;
