import {createFanHubEvent} from "data/utils/FanHubTagging";
import {
	action,
	IReactionDisposer,
	makeAutoObservable,
	observable,
	reaction,
	runInAction,
} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IMatch} from "data/types/entities";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ISettings, ISponsorStore} from "data/stores/sponsor/sponsor.store";

export interface IPicksController extends ViewController {
	trackSponsorClick: () => void;

	get matches(): IMatch[];

	get sponsorBannerSettings(): ISettings | undefined;

	get isLoading(): boolean;
}

@injectable()
export class PicksController implements IPicksController {
	@observable private _subscriptions$: IReactionDisposer[] = [];
	@observable private _isLoading: boolean = false;

	constructor(
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SponsorStore) private _sponsorStore: ISponsorStore
	) {
		makeAutoObservable(this);
	}

	public get isLoading(): boolean {
		return this._isLoading;
	}

	public get matches(): IMatch[] {
		return this._roundsStore.currentRound?.matches || [];
	}

	public get sponsorBannerSettings(): ISettings | undefined {
		return this._sponsorStore.settings;
	}

	private get roundId(): number | undefined {
		return this._roundsStore.currentRound?.id;
	}

	dispose(): void {
		this._subscriptions$.forEach((dispose) => dispose());
	}

	init(param: void): void {
		this.fetchPredictions();
		const subscription = reaction(
			() => [this.roundId],
			() => this.fetchPredictions()
		);
		this._subscriptions$.push(subscription);
		this._sponsorStore.fetchSettings();

		window.scroll({
			top: 0,
			behavior: "smooth",
		});
	}

	onChange(param: void): void {
		return;
	}

	@action
	private fetchPredictions() {
		if (!this.roundId) {
			return;
		}

		this._isLoading = true;
		this._predictionsStore
			.fetchPredictionsForRound(this.roundId)
			.catch(this._modalsStore.showErrorFromCatch)
			.finally(() => {
				runInAction(() => {
					this._isLoading = false;
				});
			});
	}

	public trackSponsorClick = () => {
		createFanHubEvent("a9400e1f-0b7a-404f-beff-8f1ac96fcc86");
	};
}
