import styled from "@emotion/styled";
import {Bindings} from "data/constants/bindings";
import {useViewController} from "data/services/locator";
import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import {IMenuController} from "views/components/menu/menu.controller";

const Wrapper = styled.div`
	background: var(--bg-white);
	position: fixed;
	top: 48px;
	left: 0;
	width: 100%;
	bottom: 0;
	transform: translateX(110%);
	transition: 0.5s;
	z-index: 1000;
	padding: 0 20px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	&.open {
		transform: translateX(0);
	}
`;

const Link = styled(NavLink)`
	padding: 14px 0;
	width: 100%;
	border-bottom: 1px solid var(--bg-cream);

	color: var(--text-default);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;

	&.active {
		font-weight: bold;
		border-color: var(--primary-default);
	}
`;

const ButtonLink = styled.button`
	padding: 14px 0;
	width: 100%;
	background: transparent;
	border: none;
	text-align: left;
	border-bottom: 1px solid var(--bg-cream);

	color: var(--text-default);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
`;

export const Menu: React.FC = observer(() => {
	const location = useLocation();
	const {i18n, menuClass, getTabUrlByMatch, close, openTutorial, trackEvent} =
		useViewController<IMenuController>(Bindings.MenuController);

	useEffect(() => {
		close();
	}, [close, location.pathname]);

	return (
		<Wrapper className={menuClass}>
			<Link to="/picks">{i18n.t("header.menu.picks", "Picks")}</Link>
			<Link
				data-event="fe7cf721-b45d-4b92-a5bb-95575916ce08"
				to="/leaderboard"
				onClick={trackEvent}>
				{i18n.t("header.menu.leaderboards", "Leaderboards")}
			</Link>
			<Link
				data-event="8ad796fd-9da6-4fd6-8117-061627f33641"
				onClick={trackEvent}
				to={getTabUrlByMatch("how-to-play")}>
				{i18n.t("header.menu.how-to-play", "How To Play")}
			</Link>
			<Link
				data-event="fbf567df-efd2-49cc-ae08-ce623f362bb7"
				onClick={trackEvent}
				to={getTabUrlByMatch("faqs")}>
				{i18n.t("header.menu.faqs", "FAQs")}
			</Link>
			<Link
				data-event="c835789b-eac8-447b-bf4d-952486ed09f7"
				onClick={trackEvent}
				to={getTabUrlByMatch("terms-&-conditions")}>
				{i18n.t("header.menu.terms", "Terms & Conditions")}
			</Link>
			<Link
				data-event="b9c370b7-78ac-4c9d-88d6-e02e5ba1fd8f"
				onClick={trackEvent}
				to={getTabUrlByMatch("contact-us")}>
				{i18n.t("header.menu.contact", "Contact")}
			</Link>
			<Link
				data-event="c3126a42-9d69-43bb-bb62-a9d1b9715b39"
				onClick={trackEvent}
				to="/my-account">
				{i18n.t("header.menu.account", "My Account")}
			</Link>
			<ButtonLink onClick={openTutorial}>
				{i18n.t("header.menu.Tutorial", "Tutorial")}
			</ButtonLink>
		</Wrapper>
	);
});
