import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";

export interface IModalReturnedUserController extends ViewController {
	close: () => void;

	get i18n(): ILocalizationStore;

	get isOpen(): boolean;

	get isLoading(): boolean;
}

@injectable()
export class ModalReturnedUserController implements IModalReturnedUserController {
	@observable private _isLoading: boolean = false;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	// Modal is disabled
	get isOpen(): boolean {
		return false;
		// if (!this.user) {
		// 	return false;
		// }
		// return !this.user.isReturningModalViewed;
	}

	private get user() {
		return this._userStore.user;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	@action
	public close = () => {
		this._isLoading = true;
		this._userStore
			.update({
				isReturningModalViewed: true,
			})
			.finally(() => {
				this._isLoading = false;
			});
	};

	onChange(param: void): void {
		return;
	}
}
