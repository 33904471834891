import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IMenuStore} from "data/stores/menu/menu.store";
import type {ISections, IStaticContentStore} from "data/stores/static_content/static_content.store";
import type {ITutorialStore} from "data/stores/tutorial/tutorial.store";
import {ViewController} from "data/types/structure";
import {createFanHubEvent} from "data/utils/FanHubTagging";
import {inject, injectable} from "inversify";
import {deburr, sortBy, toLower} from "lodash";
import {makeAutoObservable} from "mobx";
import {MouseEvent} from "react";

export interface IMenuController extends ViewController {
	close: () => void;
	openTutorial: () => void;
	getTabUrlByMatch: (name: string) => string;
	trackEvent: (event: MouseEvent<HTMLAnchorElement>) => void;

	get tabs(): ISections["sections"];

	get tabUrls(): string[];

	get i18n(): ILocalizationStore;

	get menuClass(): string;
}

@injectable()
export class MenuController implements IMenuController {
	constructor(
		@inject(Bindings.TutorialStore) private _tutorialStore: ITutorialStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.MenuStore) private _menuStore: IMenuStore,
		@inject(Bindings.StaticContentStore)
		private readonly _staticContentStore: IStaticContentStore
	) {
		makeAutoObservable(this);
	}

	public get menuClass(): string {
		return this._menuStore.isOpen ? "open" : "";
	}

	get tabs() {
		return sortBy(this._staticContentStore.sections, "position");
	}

	get tabUrls() {
		return this.tabs.map((tab) => this.buildPathName(tab.name));
	}

	public trackEvent = (event: MouseEvent<HTMLAnchorElement>) => {
		const trackUID = event.currentTarget.dataset.event;

		if (trackUID) {
			createFanHubEvent(trackUID);
		}
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onChange(param: void): void {
		return;
	}

	public close = () => {
		this._menuStore.closeMenu();
	};

	public openTutorial = () => {
		this._tutorialStore.openTutorialModal();
		this.close();
	};

	public getTabUrlByMatch = (name: string) => {
		const tabName = this.tabUrls.find((tab) => tab.includes(name)) || "";
		return `/help/${tabName || name}`;
	};

	private buildPathName = (name: string) => {
		return toLower(deburr(name)).split(" ").join("-");
	};
}
